@import (reference) '@/styles/mixins.less';

.ame-button {
  background: #FFFFFF;
  border: 1px solid #E7EAEC;
  font-size: 14px !important;
  font-weight: 400;
  color: #555756;
  line-height: 17px;
  border-radius: 10px;
  .clickable();
  height: 36px;
  outline: none !important;
  box-shadow: unset;

  &.ame-button-small {
    height: 28px;
    font-size: 13px !important;
    color: #444444;

    &:hover {
      color: #333333 !important;
    }
  }

  :global {
    .ant-btn-icon:not(:last-child) {
      margin-inline-end: 4px !important;
    }
  }

  &:not(:disabled):hover {
    color: #515352 !important;
    border-color: #DDDFE1 !important;
  }

  &:disabled {
    background-color: unset !important;
    opacity: 0.8;
  }
}

.ame-button.ame-button-primary {
  background: linear-gradient(90deg, #4DF6A9 0%, #61EFF9 100%);
  font-weight: 600;
  color: #000D0A;
  border: none;

  &:hover {
    color: #000C09 !important;
    background: linear-gradient(90deg, #49EBA1 0%, #5BE4ED 100%);
  }
}

.ame-button.ame-button-link {
  border: none;
  background: none;
  padding: 2px 4px;
  height: auto;
  .clickable();

  &:hover {
    // color: #3932E9 !important;
  }
}