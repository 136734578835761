@import (reference) './variables.less';
@import "./mixins-copy.less";

.normalize() {
  padding: 0;
  margin: 0;

  * {
    box-sizing: border-box;
  }

  ul,
  ol {
    // list-style-type: none;
  }

  img {
    vertical-align: middle;
    border-style: none;
  }

  a {
    .link();
    background-color: transparent;
  }

  textarea {
    color: @color-text;
  }

  b,
  strong {
    font-weight: bolder;
  }

  dl,
  dt,
  dd,
  ul,
  ol,
  li,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  pre,
  code,
  form,
  fieldset,
  legend,
  input,
  textarea,
  p,
  blockquote,
  th,
  td,
  hr,
  button,
  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    margin: 0;
    padding: 0;
  }
}

.clickable() {
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

.prettier-scroll-bar(@width: 3px, @height: 0px) {
  overflow-y: overlay !important;

  &::-webkit-scrollbar {
    height: @height;
    width: @width;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #dfdfdf;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #d4d4d4;
  }
}

.size(@w) {
  width: @w;
  height: @w;
}

.size(@w, @h) {
  width: @w;
  height: @h;
}

.circle(@s) {
  .size(@s);
  border-radius: @s;
}

.h-lh(@h) {
  height: @h;
  line-height: @h;
}

.flex-row() {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-col() {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.link() {
  .clickable();
  text-decoration: none;
  color: @color-primary;

  &:hover,
  &:active {
    color: @color-primary-hover;
  }
}

.link-disabled() {
  color: @color-primary-disabled;
  cursor: not-allowed;

  &:hover {
    color: @color-primary-disabled;
  }
}

.error-link() {
  .clickable();
  color: @color-danger;

  &:hover {
    color: @color-danger-hover;
  }
}

.error-link-disabled() {
  .clickable();
  color: @color-danger-disabled;
  cursor: not-allowed;

  &:hover {
    color: @color-danger-disabled;
  }
}

.textarea-overwrite() {
  .prettier-scroll-bar-default();
  .pre-wrap();
  border: 1.5px solid #e6eaf2;
  border-radius: 4px;
  box-shadow: unset !important;
  padding: 8px 12px;
  outline: 0;
  font-size: 14px;
  color: #212b36;
  line-height: 1.7;

  &::placeholder {
    color: #919eab;
  }

  &:hover {
    border-color: rgba(56, 114, 224, 0.48);
  }

  &:focus {
    border-color: @color-primary;
  }

  // disabled
  &[disabled] {
    background-color: #f5f7fa;
    border-color: #e6eaf2;
    color: #919eab;
    cursor: not-allowed;
  }
}

.markdown-overwrite() {
  font-size: 15px;
  line-height: 1.6;
  // padding-bottom: 10px;

  pre {
    margin-top: 10px;
    overflow: hidden;
  }

  &>p:not(:last-child) {
    margin-bottom: 10px;
  }

  pre code {
    white-space: pre-wrap;
    word-break: break-all;
  }

  pre code.hljs {
    padding: 0;
    background-color: transparent;
  }

  ol {
    list-style-type: auto;
  }

  ul {
    list-style-type: disc;
  }

  img {
    width: 100%;
  }

  input {
    &::placeholder {
      color: #919eab;
    }
  }

  textarea {
    &::placeholder {
      color: #919eab;
    }
  }

  a {
    text-decoration: underline;

    &:visited {
      color: unset;
    }
  }
}

.input-overwrite() {
  .pre-wrap();
  border: 1.5px solid #e6eaf2;
  border-radius: 4px;
  box-shadow: unset !important;
  padding: 4px 12px;
  outline: 0;
  font-size: 14px;
  color: #212b36;

  &::placeholder {
    color: #919eab;
  }

  &:hover {
    border-color: rgba(56, 114, 224, 0.48);
  }

  &:focus {
    border-color: @color-primary;
  }

  // disabled
  &[disabled] {
    background-color: #f5f7fa;
    border-color: #e6eaf2;
    color: #919eab;
    cursor: not-allowed;
  }
}

.text-overflow(@line: 1) {
  overflow: hidden;
  text-overflow: ellipsis;
  // white-space: nowrap;
  word-break: break-all;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.pre-wrap() {
  word-wrap: break-word;
  white-space: pre-wrap;
}

.no-tap-highlight() {
  -webkit-tap-highlight-color: transparent;
}


.kx-loading-animation {
  animation: kx-loading 1.5s linear infinite;
}

@keyframes kx-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}