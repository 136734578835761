@import (reference) '@/styles/mixins.less';

.sync-article-modal {
  :global {
    .ant-modal-content {
      height: 650px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .ant-modal-body {
        flex: 1;
        overflow: hidden;
      }

      .ant-modal-footer {
        margin: 0 !important;
      }
    }
  }
}

.modal-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;

  .modal-inner {
    flex: 1;
    overflow-y: auto;
    .prettier-scroll-bar(0);
  }
}

.desc {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  padding: 9px 24px;
  background: #F3FFF7;
  border-radius: 6px;
  border: 1px solid #E2F9E9;
  display: flex;
  align-items: center;

  >img {
    .size(18px);
    margin-right: 7px;
  }
}


.title {
  font-size: 14px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
  position: relative;
  padding-left: 11px;
  margin-top: 24px;
  margin-bottom: 20px;

  &::before {
    position: absolute;
    left: 0;
    top: 5px;
    content: '';
    width: 3px;
    height: 12px;
    background: linear-gradient(90deg, #4DF6A9 0%, #61EFF9 100%);
    border-radius: 2px;
  }
}


.account-label {
  width: 265px;
  height: 44px;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #F2F2F2;
  position: relative;
  .clickable;

  display: flex;
  align-items: center;
  padding: 8px 16px;

  .placeholder {
    flex: 1;
    color: #999;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }

  .account {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;

    .account-inner {
      flex: 1;

      >img {
        .size(28px);
        border-radius: 50%;
        margin-right: 8px;
      }

    }

    .down-icon {
      font-size: 14px;
      color: #999;
    }

  }
}

.account-dropdown-panel {
  position: absolute;
  left: 0;
  top: calc(100% + 4px);
  width: 264px;
  max-height: 225px;
  background: #FFFFFF;
  box-shadow: 0px 2px 24px 0px rgba(34, 34, 34, 0.15);
  border-radius: 4px;
  opacity: 1;
  z-index: 1;
  display: flex;
  flex-direction: column;

  &.hide {
    opacity: 0;
    z-index: -1;
    top: -9999999;
    left: -999999;
  }

  .account-list {
    flex: 1;
    // max-height: 225px;
    // max-height: 300px;
    overflow-y: auto;
    .prettier-scroll-bar(3px);

    .account-li {
      padding: 8px 16px;
      font-size: 14px;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      list-style: none;
      overflow: hidden;
      .clickable;
      display: flex;
      align-items: center;

      &:hover {
        background: #F5F7F7;
      }

      >img {
        .size(28px);
        border-radius: 50%;
        margin-right: 8px;
      }

      >span {
        flex: 1;
        display: inline-block;
        text-overflow: ellipsis;
      }
    }
  }

  .account-add-btn {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    line-height: 20px;
    .clickable;
    padding: 14px 24px;
    border-top: 1px solid #F2F2F2;

    &:hover {
      color: #00CC6F;

      .add-btn-icon {
        color: #00CC6F
      }
    }

    .add-btn-icon {
      font-size: 12px;
      color: #5C5C65;
      margin-right: 10px;
    }
  }
}

.sub-desc {
  font-size: 13px;
  font-weight: 400;
  color: #999999;
  line-height: 18px;
  margin-top: 8px;
  margin-bottom: 40px;
}


.article-setting {
  .setting {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;

    .required {
      position: relative;

      &::before {
        position: absolute;
        content: '*';
        color: #FF1B1B;
        left: -8px;
        top: 0;
      }
    }

    .label {
      margin-top: 8px;
      min-width: 50px;
      text-align: right;

      &.required {
        position: relative;

        &::before {
          position: absolute;
          left: 0;
          top: 0;
          transform: translateX(0px);
          content: '*';
          color: red;
          z-index: 1;
        }
      }
    }

    .input-box {
      width: 378px;
    }

    .textarea-box {
      width: 638px;
    }

    .upload-box {
      width: 112px;
      height: 112px;

    }

    .upload-btn {
      width: 112px;
      height: 112px;
      background: #FAFBFB;
      border-radius: 10px;
      border: 1px solid #F2F2F2;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 28px;
      color: #5C5C65;
      .clickable;
      position: relative;

      &:hover {
        border-color: #75DBAA;

        .add-btn {
          color: #75DBAA;
        }
      }

      .clearImgBtn {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 21px;
        transform: translate(50%, -50%);
      }

      >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

}

.btn-box {
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    width: 92px;
    height: 40px;
  }

  .confirm-btn {
    margin-left: 12px;
  }

}