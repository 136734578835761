@import (reference) '../../styles/mixins.less';

.dropdown-box {
  display: inline-flex;
  position: relative;
  z-index: 1;

  .dropdown-label {
    display: inline-flex;
  }

  .dropdown-menu {
    position: absolute;
    left: 0;
    top: calc(100% + 4px);
    opacity: 1;
    z-index: 1;

    &.hide {
      left: -99999px;
      top: -99999px;
      opacity: 0;
      z-index: -1;
    }
  }
}