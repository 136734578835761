@import (reference) '../../styles/mixins.less';

.desc1 {
  background: #F3FFF7;
  border-radius: 6px;
  border: 1px solid #E2F9E9;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 22px;
  padding: 9px 24px;
  display: flex;
  align-items: center;
  width: 100%;

  >img {
    .size(18px);
    margin-right: 7px;
  }
}

.desc2 {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;

  &:nth-of-type(2) {
    margin-top: 4px;
  }
}

.empty-img {
  width: 190px;
  height: 144px;
  margin-top: 32px;
  margin-bottom: 24px;
}

.auth-btn {
  margin-top: 48px;
  width: 246px;
  height: 40px;
}