@import (reference) '@/styles/mixins.less';

.login-modal {
  :global {
    .ant-modal-content {
      padding: 0px !important;
      border-radius: 16px !important;
    }
  }

  .login-content {
    .flex-row();
    .size(100%, 529px);

    >.left {
      .size(302px, 100%);
      position: relative;
      padding: 64px 28px;

      .bg {
        .size(100%);
        position: absolute;
        left: 0;
        top: 0;
      }

      >div {
        position: relative;
        z-index: 2;

        >.title {
          // .flex-center-v;
          font-size: 24px;
          font-weight: 600;
          color: #233E50;
          line-height: 33px;

          img {
            width: 13px;
            margin-right: 8px;
          }
        }

        >.desc {
          font-size: 12px;
          font-weight: 400;
          color: #415766;
          line-height: 17px;
          margin-top: 6px;
          margin-bottom: 32px;
        }

        >.item {
          background: rgba(252, 254, 254, 0.85);
          border-radius: 8px;
          border: 1px solid #FFFFFF;
          margin-bottom: 18px;
          padding: 10px 18px;

          p {
            .flex-center-v;
            font-size: 13px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
            color: #000D0A;
            line-height: 18px;

            img {
              width: 16px;
              margin-right: 5px;
            }

            &.desc {
              font-size: 12px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #999999;
              line-height: 17px;
              margin-top: 4px;
            }
          }
        }
      }
    }

    >.right {
      flex: 1;
      .flex-col();
      padding: 68px 0 32px;

      >.title {
        font-size: 18px;
        font-weight: 600;
        color: #2C2C2C;
        line-height: 25px;
        margin-bottom: 32px;
      }

      .qr-code-box {
        width: 252px;
        height: 252px;
        background: #FFFFFF;
        box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        .flex-center;
        position: relative;

        .qr-code-loading {
          .size(30px);
        }

        .qr-code {
          width: 212px;
        }

        .mask-box{
          .size(100%);
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255, 255, 255, .95);
          position: absolute;
          top: 0;
          left: 0;
          font-weight: 400;
          font-size: 13px;
          color: #353535;
          line-height: 18px;
          text-align: center;
        }
      }

      >.desc {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #666666;
        line-height: 17px;
        margin-top: 24px;
      }

      .protocol-box {
        margin-top: 60px;
        .tips {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;
          color: #333333;

          .checkbox-btn {
            margin-right: 2px;
            padding: 4px;
            cursor: pointer;

            &.checkbox-active {
              .check-hover, .check-normal {
                display: none;
              }

              .check-active {
                display: block
              }
            }

            &:not(.checkbox-active):hover {
              .check-hover {
                display: block
              }

              .check-normal {
                display: none
              }
            }

            img {
              .size(14px);
              display: block;
            }

            .check-hover, .check-active {
              display: none;
            }
          }

          a {
            color: rgba(69, 154, 233, 1) !important;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }


}