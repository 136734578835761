@import (reference) '../../styles/mixins.less';

.form-input {
  .input-box, .textarea-box {
    width: 100%;
    background: #FAFBFB;
    border-radius: 10px;
    border: 1px solid #F2F2F2;
    font-size: 14px;
    font-weight: 400;
    color: #262626;
    line-height: 20px;
    height: 44px;

    &:not(.input-disable):hover {
      border-color: #75DBAA;

      .word-count {
        display: unset
      }
    }

    &.input-disable{
      opacity: 0.6
    }
  }

  .input-box {
    .flex-row();
    padding-right: 16px;
    .word-count {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      display: none;
    }

    .line {
      width: 1px;
      height: 16px;
      border-left: 1px solid #E6E6E7;
      margin: 0 16px;
    }

    .add-btn {

      color: #18C978;
      cursor: pointer;
      user-select: none;
    }
  }

  .input {
    width: 100%;
    outline: none;
    resize: none;
    border: none;
    flex: 1;
    background-color: unset;
    padding: 12px 16px;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    &[type='number'] {
      -moz-appearance: textfield;
    }
    &::placeholder {
      color: #999999;
    }


    &.multiple-input {
      height: 107px;
      .prettier-scroll-bar(3px);
      border: none;
      padding: 0 16px;
    }
  }

  .textarea-box {
    position: relative;
    height: 153px;
    padding: 12px 0px 24px;
    overflow-y: auto;
    border: 1px solid #F2F2F2;
    background: #FAFBFB;
    border-radius: 10px;

    .word-count {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
      line-height: 17px;
      position: absolute;
      right: 16px;
      bottom: 12px;
    }
  }
}