@import (reference) '@/styles/mixins.less';

.share-article-link-modal {
  position: relative;
  z-index: 1;
}

.share-article-link-modal-content {
  position: relative;
  z-index: 1;
}

.bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 123px;
  background: linear-gradient(180deg, #E6F9FF 0%, #FFFFFF 100%);
  border-radius: 8px;
  // z-index: -1;

  >img {
    .size(100%);
  }
}

.title {
  font-size: 21px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 29px;
}

.desc {
  font-size: 16px;
  font-weight: 600;
  color: #242226;
  line-height: 27px;
  margin-top: 8px;
}

.copy-box {
  margin-top: 32px;
  width: 548px;
  height: 54px;
  background: #FFFFFF;
  border-radius: 10px;
  border: 1px solid #F0F0F0;
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 4px;

  .link {
    flex: 1;
    font-size: 15px;
    font-weight: 400;
    color: #1C1E22;
    line-height: 22px;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 16px;
  }

  .copy-btn {
    width: 120px;
    height: 46px;
    font-size: 16px !important;
  }
}