@import (reference) '../../styles/mixins.less';

.ame-modal {
  color: #333333;

  &.common-qr-dialog {
    :global {
      .ant-modal-title {
        text-align: center;
      }

      .ant-modal-body {
        .flex-col;
      }
    }
  }

  :global {
    .ant-modal-header {
      margin-bottom: 12px;

      .ant-modal-title {
        color: #222222;
      }
    }

    .ant-modal-content {
      padding: 24px 32px;
    }

    .ant-modal-footer {
      margin-top: 24px;

      .ant-btn+.ant-btn {
        margin-inline-start: 12px !important;
      }

      .ant-btn {
        border-radius: 8px;
        height: 36px;
        padding: 0 24px;
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;

      }

      .ant-btn-default {
        background: #FFFFFF;
        border: 1px solid #DDDDDD;
        color: #333333;

        &:hover {
          color: #515352;
          border-color: #DDDFE1;
        }
      }

      .ant-btn-primary {
        background: linear-gradient(90deg, #4DF6A9 0%, #61EFF9 100%);
        color: #000D0A;

        &:hover {
          color: #000C09;
          background: linear-gradient(90deg, #49EBA1 0%, #5BE4ED 100%);
        }
      }
    }
  }
}