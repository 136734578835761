@import (reference) '@/styles/mixins.less';

.pay-success-dialog {
  width: 509px !important;

  :global {
    .ant-modal-content {
      padding: 24px 0 48px !important;
      border-radius: 16px !important;
      height: 360px;
    }
    .ant-btn{
      width: 204px !important;
    }
  }

  .content {
    width: 100%;
    .flex-col;
  }

  .title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #222222;
    line-height: 22px;
  }

  .bg {
    width: 171px;
    margin: 46px 0 60px;
  }
}