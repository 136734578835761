@import (reference) '@/styles/mixins.less';

.sync-img {
  .size(171px, 120px);
  margin-top: 11px;
}

.desc {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
  margin-top: 23px;
}

.btn-box {
  margin-top: 40px;

  .btn {
    width: 204px;
    height: 40px;
  }


  .confirm-btn {
    margin-left: 24px;

  }
}