@import (reference) '../../styles/mixins.less';

.common-dialog-layout {}

.contact-dialog {
  .desc {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    margin-top: 14px;
    margin-bottom: 34px;
  }
}

.no-enough-token-dialog {
  .no-free-word-tips {
    width: 100%;
    margin-bottom: 34px;

    p {
      width: 100%;
      padding: 9px 24px;
      background: linear-gradient(136deg, #FEFDE0 0%, #FFFEEA 15%, #FEFDE0 100%);
      border-radius: 6px;
      border: 1px solid #FFF8CE;
      font-size: 14px;
      font-weight: 400;
      color: #723D15;
      line-height: 22px;

      img {
        width: 18px;
        margin-right: 8px;
      }
    }
  }
}

.qr-box {
  .size(198px);
  position: relative;

  .bg {
    .size(100%);
  }

  .qr {
    width: 191px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.free-word-dialog {
  :global {
    .ant-modal-content {
      padding: 32px 32px 24px !important;
      border-radius: 16px !important;
    }
  }

  .bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }

  .title {
    font-size: 16px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #1A1A1A;
    .flex-row;
    position: relative;
    z-index: 2;

    img {
      width: 20px;
      margin-right: 9px;
    }
  }

  .desc {
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    line-height: 24px;
    margin: 6px 0 207px;
    text-align: center;
    position: relative;
    z-index: 2;
  }
}