@import (reference) '@/styles/mixins.less';

.pay-dialog {
  width: 761px !important;

  :global {
    .ant-modal-content {
      padding: 0px !important;
      border-radius: 16px !important;
    }
  }

  .header {
    width: 100%;
    height: 95px;
    background: linear-gradient(90deg, #4DF6A9 0%, #61EFF9 100%);
    border-radius: 16px;
    padding-left: 32px;
    padding-top: 10px;
    display: flex;

    .avatar {
      .circle(40px);
      background: #D8D8D8;
      border: 1px solid #FAF5FF;
      margin-right: 12px;
    }

    >div {
      span {
        display: block;
      }
    }

    .nickname {
      font-size: 15px;
      font-weight: 600;
      color: #000D0A;
      line-height: 21px;
    }

    .expired-time {
      font-size: 12px;
      font-weight: 400;
      color: #267A5D;
      line-height: 17px;
      margin-top: 2px;
    }
  }

  .box {
    width: 100%;
    height: 457px;
    background: #FFFFFF;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    margin-top: -36px;
    .flex-row();

    .left {
      padding: 40px;
      width: 214px;
      height: 100%;
      position: relative;
      background: linear-gradient(161deg, #F8FBFA 0%, #F8FBF9 100%);
      border-radius: 16px 0px 0px 16px;

      .left-bg {
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }

      >div {
        position: relative;
        z-index: 2;
      }

      .logo {
        width: 98px;
        margin-bottom: 24px;
      }

      .item {
        padding-bottom: 16px;
        margin-bottom: 16px;

        &:not(:last-child) {
          border-bottom: 1px dashed #F2F2F2;
        }

        >p {
          .flex-row();
          font-size: 13px;
          font-weight: 600;
          color: #000D0A;
          line-height: 18px;
          justify-content: flex-start;

          img {
            width: 16px;
            margin-right: 2px;
          }

          &.desc {
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 17px;
            margin-top: 5px;
          }
        }
      }
    }

    .right {
      flex: 1;
      height: 100%;
      padding: 44px 40px 0;
      border-left: 1px solid #F2F2F2;

      .plan-list {
        .flex-row();
        justify-content: space-between;

        .plan {
          width: 221px;
          height: 137px;
          background: #FFFFFF;
          border-radius: 16px;
          border: 2px solid #E7E6E6;
          position: relative;
          .flex-col();
          padding-top: 20px;
          justify-content: flex-start;
          .clickable();

          .tips {
            width: 84px;
            height: 28px;
            line-height: 28px;
            text-align: center;
            background: linear-gradient(160deg, #FFF373 0%, #FFEA00 100%);
            border-radius: 16px 16px 16px 2px;
            border: 1px solid #FEEA07;
            position: absolute;
            right: -14px;
            top: -12px;
            font-size: 13px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #723D15;
          }

          .plan-time {
            font-size: 14px;
            font-weight: 600;
            color: #000000;
            line-height: 20px;
            margin-bottom: 5px;
          }

          .price-box {
            color: #333333;
            line-height: 21px;
            font-size: 36px;
            font-weight: 600;

            span {
              font-size: 15px;
              font-weight: 400;

            }
          }

          .unit-price {
            width: 100%;
            height: 32px;
            background: #ECFDF7;
            position: absolute;
            border-radius: 0 0 14px 14px;
            left: 0;
            bottom: 0;
            .flex-center;
            color: #00C46A;
          }

          &:hover {
            border: 2px solid #00DBB7;
          }

          &.active {
            border: 2px solid #00DBB7;

            .unit-price {
              background: #00DBB7;
              color: #FFFFFF;
            }
          }
        }
      }

      .order-box {
        margin-top: 40px;

        .title {
          color: #2C2C2C;
          .flex-row();
          margin-bottom: 16px;
          justify-content: flex-start;
          font-size: 13px;

          img {
            width: 18px;
            margin-right: 4px;
          }

          &::before {
            width: 3px;
            height: 12px;
            background: linear-gradient(135deg, #4DF6A9 0%, #61EFF9 100%);
            border-radius: 1px;
            content: '';
            display: block;
            margin-right: 6px;
          }
        }

        .order {
          display: flex;

          .img-box {
            width: 144px;
            height: 144px;
            padding: 6px;
            position: relative;
            margin-right: 35px;

            .qr {
              width: 100%;
              position: relative;
              z-index: 2;
            }

            .qr-bg {
              width: 100%;
              position: absolute;
              left: 0;
              top: 0px;
            }
          }


        }

        .pay-channel {
          .flex-row();
          font-size: 14px;
          font-weight: 600;
          color: #2C2C2C;
          margin-bottom: 12px;
          justify-content: flex-start;


          .icon {
            width: 20px;
            margin-right: 6px;
          }
        }

        .row-two {
          font-size: 13px;
          color: #2C2C2C;
          margin-bottom: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;

          .label {
            color: #999999;
          }
        }

        .price {
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: #18C978;
          line-height: 45px;
          border-top: 1px solid #F2F2F2;
          padding-top: 8px;
          margin-top: 15px;
        }
      }
    }
  }
}