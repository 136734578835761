.size(@size) {
  width: @size;
  height: @size;
}

.size(@width, @height) {
  width: @width;
  height: @height;
}

.transition(@attr, @time: 0.2s, @easing: ease, @delay: 0s) {
  transition+: @attr @time @easing @delay;
}

.clearfix() {
  &::after {
    display: table;
    content: "";
    clear: both;
  }
}

.filter(@attr) {
  filter+_: @attr;
}

.absolute(@all: 0) {
  position: absolute;
  top: @all;
  right: @all;
  bottom: @all;
  left: @all;
}

.absolute(@top, @right, @bottom, @left) {
  position: absolute;
  top: @top;
  right: @right;
  bottom: @bottom;
  left: @left;
}

.padding-tb(@all) {
  .padding-tb(@all, @all);
}

.padding-tb(@top, @bottom) {
  padding-top: @top;
  padding-bottom: @bottom;
}

.padding-lr(@all) {
  .padding-lr(@all, @all);
}

.padding-lr(@left, @right) {
  padding-left: @left;
  padding-right: @right;
}

.fixed(@top: 0, @right: 0, @bottom: 0, @left: 0) {
  position: fixed;
  top: @top;
  right: @right;
  bottom: @bottom;
  left: @left;
}

.text-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}

.t-m-ellipses(@line) {
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: @line;
  -webkit-box-orient: vertical;
}

.h-lh(@height) {
  height: @height;
  line-height: @height;
}

.circle(@size) {
  width: @size;
  height: @size;
  border-radius: @size / 2;
}

.flex-center() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center-v() {
  display: flex;
  align-items: center;
}

//pseudo arrows
.pseudo-arrow(@size: 10px) {
  box-sizing: content-box;
  content: "";
  display: inline-block;
  .size(0);
  border: @size solid transparent;
}

.pseudo-arrow-up(@size: 10px, @color: white) {
  .pseudo-arrow(@size);
  border-bottom-color: @color;
}

.pseudo-arrow-down(@size: 10px, @color: white) {
  .pseudo-arrow(@size);
  border-top-color: @color;
}

.pseudo-arrow-left(@size: 10px, @color: white) {
  .pseudo-arrow(@size);
  border-right-color: @color;
}

.pseudo-arrow-right(@size: 10px, @color: white) {
  .pseudo-arrow(@size);
  border-left-color: @color;
}

.pseudo-border-h(@color, @padding: 10px, @size: 1px) {
  position: absolute;
  left: @padding;
  right: @padding;
  content: "";
  display: block;
  height: @size;
  background: @color;
}

.pseudo-border-top(@color, @padding: 10px, @size: 1px) {
  .pseudo-border-h(@color, @padding, @size);
  top: 0px;
}

.pseudo-border-bottom(@color, @padding: 10px, @size: 1px) {
  .pseudo-border-h(@color, @padding, @size);
  bottom: 0px;
}


// placeholder 的颜色修改
.placeholder-color(@color) {
  input::-webkit-input-placeholder {
    color: @color;
  }
}

.pretty-scroll-bar(@width: 6px) {
  &::-webkit-scrollbar {
    .size(@width);
  }

  &::-webkit-scrollbar-thumb {
    .size(@width);
    border-radius: 10px;
    background-color: #d5d5d5;
    cursor: pointer;

    &:hover {
      background-color: #ccc;
    }
  }

  scrollbar-width: thin;
}

.icon-vertical-bias() {
  position: relative;
  top: 1px;
}

.row-flex() {
  display: flex;
  align-items: center;

  .col-flex {
    flex: 1;
  }

  .col {
    display: block;
  }
}

.bd(@color: @bd-alt) {
  border: 1px solid @color;
}

.relative() {
  position: relative;
}

.bg-img(@url) {
  background-image: url(@url);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
}

.bg(@color: @bg-l) {
  background-color: @color;
}

.text-center() {
  text-align: center;
}

.typ-s() {
  font-size: @fs-s;
  line-height: @lh-s;
}


.text-hidden() {
  font-size: 0;
}

.arrow-down(@size: 4px, @color: @bd-d) {
  .size(0);
  .relative;
  top: @size/2;
  border: @size solid transparent;
  border-top: @size solid @color;
  border-bottom: none;
}

.btn-a() {
  text-decoration: none !important;
  display: inline-block;
  outline: none;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.btn-a(@width; @height) {
  .btn-a;
  .h-lh(@height);
  width: @width;
}

.btn-button() {
  display: inline-block;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
}

.btn-button(@width; @height) {
  .btn-button;
  .size(@width, @height);
}