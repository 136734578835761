@import (reference) '../../styles/mixins.less';


.desc1 {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;

}

.desc2 {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
}

.desc3 {
  font-size: 13px;
  font-weight: 600;
  color: #333333;
  line-height: 20px;
}

.desc4 {
  font-size: 12px;
  font-weight: 400;
  color: #666666;
  line-height: 20px;
}

.intro-img {
  .size(156px, 36px);
  margin-top: 24px;
}

.qr-box {
  .size(236px);
  background: #FFFFFF;
  box-shadow: 0px 4px 19px 0px rgba(0, 0, 0, 0.08);
  border-radius: 9px;
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;

  .qr-code {
    .size(204px);
  }

  .loading {
    .size(24px);
  }
}

.intro-descs {
  margin-top: 24px;
  width: 491px;
  height: 90px;
  background: #FAFBFB;
  border-radius: 10px;
  padding-top: 12px;
  padding-left: 20px;
}